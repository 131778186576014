import React from 'react';
import { graphql } from 'gatsby';
import { Hero } from '../generated/graphql';
import styled from 'styled-components';
import { SPBox } from './SPBox';
import { SpecialAbilityBox } from './SpecialAbilityBox';

interface Props {
  hero: Hero;
}

const AbilityWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const HeroAbilities = ({ hero }: Props) => {
  return (
    <>
      {hero.abilities.sp1.map((ability) => (
        <SPBox key={ability.title} ability={ability} title={'SP1'} />
      ))}
      {hero.abilities.sp2.map((ability) => (
        <SPBox key={ability.title} ability={ability} title={'SP2'} />
      ))}
      {hero.abilities.sp3.map((ability) => (
        <SPBox key={ability.title} ability={ability} title={'SP3'} />
      ))}
      {hero.abilities.sp4.map((ability) => (
        <SPBox key={ability.title} ability={ability} title={'SP4'} />
      ))}
    </>
  );
};

export const heroStatFragment = graphql`
  fragment HeroAbilities on SanityHero {
    abilities {
      damage_type
      _type
      _key
      specials {
        title
        description
      }
      sp1 {
        ... on SanityAttack {
          _key
          _type
          affects_stat
          aoe_radius
          burn
          burn_chance
          burn_damage
          burn_length
          cooldown
          description
          freeze
          freeze_chance
          freeze_duration
          is_aoe
          poison
          poison_chance
          poison_damage
          poison_length
          stat_amount
          stat_length
          stun
          stun_chance
          stun_duration
          title
        }
        ... on SanityBuff {
          _key
          _type
          title
          is_aoe
          description
          cooldown
          buff_length
          aoe_radius
          amount
          affects_stat
          affects_element
          affects_class
        }
        ... on SanityDebuff {
          _key
          _type
          affects_class
          affects_element
          affects_stat
          amount
          aoe_radius
          blind
          blind_chance
          blind_length
          description
          is_aoe
          title
        }
      }
      sp2 {
        ... on SanityAttack {
          _key
          _type
          affects_stat
          aoe_radius
          burn
          burn_chance
          burn_damage
          burn_length
          cooldown
          description
          freeze
          freeze_chance
          freeze_duration
          is_aoe
          poison
          poison_chance
          poison_damage
          poison_length
          stat_amount
          stat_length
          stun
          stun_chance
          stun_duration
          title
        }
        ... on SanityBuff {
          _key
          _type
          title
          is_aoe
          description
          cooldown
          buff_length
          aoe_radius
          amount
          affects_stat
          affects_element
          affects_class
        }
        ... on SanityDebuff {
          _key
          _type
          affects_class
          affects_element
          affects_stat
          amount
          aoe_radius
          blind
          blind_chance
          blind_length
          description
          is_aoe
          title
        }
      }
      sp3 {
        ... on SanityAttack {
          _key
          _type
          affects_stat
          aoe_radius
          burn
          burn_chance
          burn_damage
          burn_length
          cooldown
          description
          freeze
          freeze_chance
          freeze_duration
          is_aoe
          poison
          poison_chance
          poison_damage
          poison_length
          stat_amount
          stat_length
          stun
          stun_chance
          stun_duration
          title
        }
        ... on SanityBuff {
          _key
          _type
          title
          is_aoe
          description
          cooldown
          buff_length
          aoe_radius
          amount
          affects_stat
          affects_element
          affects_class
        }
        ... on SanityDebuff {
          _key
          _type
          affects_class
          affects_element
          affects_stat
          amount
          aoe_radius
          blind
          blind_chance
          blind_length
          description
          is_aoe
          title
        }
      }
      sp4 {
        ... on SanityBuff {
          _key
          _type
          title
          is_aoe
          description
          cooldown
          buff_length
          aoe_radius
          amount
          affects_stat
          affects_element
          affects_class
        }
        ... on SanityDebuff {
          _key
          _type
          affects_class
          affects_element
          affects_stat
          amount
          aoe_radius
          blind
          blind_chance
          blind_length
          description
          is_aoe
          title
        }
      }
    }
  }
`;
