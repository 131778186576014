import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BaseStats, Hero } from '../generated/graphql';
import { Stars } from './Stars';
import { Box } from './styled/Box';
import { H3 } from './styled/H3';
import { usePrettify } from '../hooks/usePrettify';

interface Props {
  hero: Hero;
}

const BoxInner = styled.div`
  display: grid;
  gap: 2rem;
`;

const StatWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  gap: 1rem;

  @media (max-width: 400px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
`;

const Stat = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  border-top: 1px solid var(--surface-border);
  border-bottom: 1px solid var(--surface-border);
  border-left: 1px solid var(--surface-border);
  border-right: 1px solid var(--surface-border);
  padding: 1rem 2rem;
  /* transition: var(--standard-transition); */
  .stat {
    font-weight: 500;
  }
`;

const StarWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 1fr;
  justify-content: start;
  align-items: center;
  gap: 1rem;
`;

const primaryStats = [
  'attack',
  'hp',
  'defense',
  'critical_rate',
  'critical_damage',
  'attacks_per_second',
  'attack_range',
  'movement_speed',
  'effect_resistance',
];

export const HeroStats = ({ hero }: Props) => {
  const [stars, setStars] = useState(1);
  const [awakens, setAwakens] = useState(0);

  const makeVal = (input: number) => {
    let newVal = input;
    for (let index = 1; index < stars; index++) {
      newVal = newVal * 2;
    }
    for (let index = 0; index < awakens; index++) {
      newVal = newVal * 1.5;
    }
    return newVal;
  };

  const makeStats = (stats: BaseStats, type: 'primary' | 'secondary') => {
    const statsArr: JSX.Element[] = [];

    if (type === 'primary') {
      for (const [key, value] of Object.entries(stats)) {
        if (primaryStats.includes(key)) {
          if (!key.startsWith('_')) {
            const val = typeof value === 'string' ? parseInt(value) : value;
            let result;
            switch (key.toLowerCase()) {
              case 'attack':
                result = (
                  <Stat key={key}>
                    <span className='stat'>{usePrettify(key)}</span>
                    <span>{makeVal(val) || 0}</span>
                  </Stat>
                );
                statsArr.push(result);
                break;
              case 'hp':
                result = (
                  <Stat key={key}>
                    <span className='stat'>{usePrettify(key)}</span>
                    <span>{makeVal(val) || 0}</span>
                  </Stat>
                );
                statsArr.push(result);
                break;
              case 'defense':
                result = (
                  <Stat key={key}>
                    <span className='stat'>{usePrettify(key)}</span>
                    <span>{makeVal(val) || 0}</span>
                  </Stat>
                );
                statsArr.push(result);
                break;

              default:
                result = (
                  <Stat key={key}>
                    <span className='stat'>{usePrettify(key)}</span>
                    <span>{val || 0}</span>
                  </Stat>
                );
                statsArr.push(result);
                break;
            }
          }
        }
      }
    }

    if (type === 'secondary') {
      for (const [key, value] of Object.entries(stats)) {
        if (!primaryStats.includes(key)) {
          if (!key.startsWith('_')) {
            const val = typeof value === 'string' ? parseInt(value) : value;
            let result;
            switch (key.toLowerCase()) {
              case 'attack':
                result = (
                  <Stat key={key}>
                    <span className='stat'>{usePrettify(key)}</span>
                    <span>{makeVal(val) || 0}</span>
                  </Stat>
                );
                statsArr.push(result);
                break;
              case 'hp':
                result = (
                  <Stat key={key}>
                    <span className='stat'>{usePrettify(key)}</span>
                    <span>{makeVal(val) || 0}</span>
                  </Stat>
                );
                statsArr.push(result);
                break;
              case 'defense':
                result = (
                  <Stat key={key}>
                    <span className='stat'>{usePrettify(key)}</span>
                    <span>{makeVal(val) || 0}</span>
                  </Stat>
                );
                statsArr.push(result);
                break;

              default:
                result = (
                  <Stat key={key}>
                    <span className='stat'>{usePrettify(key)}</span>
                    <span>{val || 0}</span>
                  </Stat>
                );
                statsArr.push(result);
                break;
            }
          }
        }
      }
    }

    statsArr.sort((a, b) => {
      if (a.key > b.key) {
        return 1;
      }
      if (a.key < b.key) {
        return -1;
      }
      return 0;
    });

    return statsArr;
  };

  useEffect(() => {
    if (awakens > stars) {
      setAwakens(() => stars);
    }
  }, [stars, awakens]);

  return (
    <Box>
      <BoxInner>
        <div>
          <H3>Stars</H3>
          <StarWrapper>
            <button
              disabled={stars === 1}
              onClick={() => setStars(() => stars - 1)}
            >
              -
            </button>
            <Stars number={stars} />
            <button
              disabled={stars >= 7}
              onClick={() => setStars(() => stars + 1)}
            >
              +
            </button>
          </StarWrapper>
        </div>
        <div>
          <H3>Awakens</H3>
          <StarWrapper>
            <button
              disabled={awakens === 0}
              onClick={() => setAwakens(() => awakens - 1)}
            >
              -
            </button>
            <Stars number={awakens} />
            <button
              disabled={awakens >= stars}
              onClick={() => setAwakens(() => awakens + 1)}
            >
              +
            </button>
          </StarWrapper>
          <small>
            <i>Awaken level cannot exceed Star level</i>
          </small>
        </div>
        <div>
          <H3>Primary Stats</H3>
          <StatWrapper>{makeStats(hero.base_stats, 'primary')}</StatWrapper>
        </div>
        <div>
          <H3>Secondary Stats</H3>
          <StatWrapper>{makeStats(hero.base_stats, 'secondary')}</StatWrapper>
        </div>
      </BoxInner>
    </Box>
  );
};

export const heroStatFragment = graphql`
  fragment HeroStats on SanityHero {
    base_stats {
      _key
      _type
      aoe_damage
      aoe_radius
      attack
      ultimate_attack
      stun_time
      stun_chance
      poison_time
      poison_damage
      poison_chance
      movement_speed
      knights_shield_hp
      hp
      healing_rate
      frenzy_chance
      freeze_time
      freeze_explosion_damage
      freeze_chance
      effect_resistance
      dodge_rate
      defense
      critical_rate
      critical_damage
      burn_time
      burn_damage
      burn_chance
      bonus_gold
      attacks_per_second
      attack_range
    }
  }
`;
