// Returns the original string without '_' and with each
// word capitalized

export const usePrettify = (input: string): string => {
  return input
    .replace(/_/g, ' ')
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};
