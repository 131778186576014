import React from 'react';
import { Hero } from '../generated/graphql';
import { Box } from './styled/Box';
import { H3 } from './styled/H3';
import { Tag } from './styled/Tag';
import { TagWrapper } from './styled/TagWrapper';
import { usePrettify } from '../hooks/usePrettify';

interface Props {
  hero: Hero;
}

const rarityDescriptions = {
  common: `Common hero medals can be found in many boxes.`,
  rare: `Rare hero medals are slightly harder to find than common heroes, but can still be found in many places.`,
  epic: `Epic hero medals are hard to find, but they make a fine addition to any team.`,
  legendary: `Legendary hero medals are the most rare and powerful.`,
};

export const Rarity = ({ hero }: Props) => {
  return (
    <Box>
      <H3>{hero.rarity}</H3>
      <p>
        {rarityDescriptions[hero.rarity]} Medals for {hero.name} can be found
        in:
      </p>
      <TagWrapper>
        {hero.medal_sources.map((source) => (
          <Tag key={source} type='medal'>
            {usePrettify(source)}
          </Tag>
        ))}
      </TagWrapper>
    </Box>
  );
};
