import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
  capitalize?: boolean;
}

const StyledH3 = styled.h3`
  text-transform: capitalize;
  /* transition: var(--standard-transition); */
`;

export const H3 = ({ children, capitalize }: Props) => {
  return <StyledH3>{children}</StyledH3>;
};
