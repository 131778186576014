import React from 'react';
import { Hero } from '../generated/graphql';
import { Box } from './styled/Box';
import { H3 } from './styled/H3';

interface Props {
  hero: Hero;
}

const elementDescriptions = {
  water: `This hero has a Water affinity. They will do bonus damage against Fire heroes and less damage against Earth heroes.`,
  fire: `This hero has a Fire affinity. They will do bonus damage against Earth heroes and less damage against Water heroes.`,
  earth: `This hero has an Earth affinity. They will do bonus damage against Water heroes and less damage against Fire heroes.`,
  light: `This hero has a Light affinity. They will do bonus damage against Dark heroes.`,
  dark: `This hero has a Dark affinity. They will do bonus damage against Light heroes.`,
};

export const Elements = ({ hero }: Props) => {
  return (
    <Box>
      <H3>{hero.element}</H3>
      <p>{elementDescriptions[hero.element]}</p>
    </Box>
  );
};
