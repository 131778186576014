import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as Star2 } from '@fortawesome/free-regular-svg-icons';
interface Props {
  number: number;
}

const StarWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  font-size: 1.2rem;
  color: var(--surface-alt);
  .gold {
    color: var(--yellow-500);
  }
`;

export const Stars = ({ number }: Props) => {
  const makeStars = () => {
    const stars: JSX.Element[] = [];

    for (let index = 0; index < number; index++) {
      stars.push(
        <FontAwesomeIcon
          className={'gold'}
          icon={faStar}
          key={`filled${index}`}
        />
      );
    }
    for (let index = number + 1; index < 8; index++) {
      stars.push(<FontAwesomeIcon icon={Star2} key={`empty${index}`} />);
    }

    return stars;
  };

  return <StarWrapper>{makeStars()}</StarWrapper>;
};
