import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { Hero } from '../generated/graphql';
import { Box } from './styled/Box';
import { H3 } from './styled/H3';

interface Props {
  hero: Hero;
}

const Gridder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const genderDescriptios = {
  male: `This hero is a male.`,
  female: `This hero is a female.`,
};
const heroTypeDescriptions = {
  ground: `This hero walks.`,
  flying: `This hero flies!`,
};

export const HeroMeta = ({ hero }: Props) => {
  return (
    <Box>
      <Gridder>
        <div>
          <H3>{hero.gender}</H3>
          <p>{genderDescriptios[hero.gender]}</p>
        </div>
        <div>
          <H3>{hero.hero_type}</H3>
          <p>{heroTypeDescriptions[hero.hero_type]}</p>
        </div>
      </Gridder>
    </Box>
  );
};

export const heroMetaFragment = graphql`
  fragment HeroMeta on SanityHero {
    element
    gender
    hero_type
    rarity
    class
    crusher
  }
`;
