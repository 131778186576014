import React from 'react';
import { Attack, Buff, Debuff, Hero } from '../generated/graphql';
import { Box } from './styled/Box';
import { H3 } from './styled/H3';

interface Props {
  ability: Attack | Buff | Debuff;
  title: string;
}

export const SPBox = ({ ability, title }: Props) => {
  return (
    <Box>
      <div key={ability.title}>
        <H3>
          {title} - {ability.title}
        </H3>
        <div>{ability.description}</div>
      </div>
    </Box>
  );
};
