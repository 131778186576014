import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
}

const StyledTagWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  transition: var(--standard-transition);
  margin: 1rem 0;
`;

export const TagWrapper = ({ children }: Props) => {
  return <StyledTagWrapper>{children}</StyledTagWrapper>;
};
