import React from 'react';
import { Hero } from '../generated/graphql';
import { Box } from './styled/Box';
import { H3 } from './styled/H3';

interface Props {
  hero: Hero;
}

const classDescriptions = {
  archer: `Archers are good at hitting distant or flying enemies. They will critically hit all flying enemies.`,
  magician: `Magicians are good at shooting distant enemies and casting status effects.`,
  rogue: `Rogues are really fast runners and can dodge attacks. Each enemy killed will also give 2x gold.`,
  lancer: `Lancers have a chance to stun the enemy with each attack.`,
  barbarian: `Tanks have a strong body and can withstand a lot of damage.`,
  gunner: `Gunners can pierce anything and will ignore the DEF of the enemy.`,
  knight: `Knights have a strong shield which will take all the damange until it's destroyed.`,
  support: `Support heroes are really good at protecting or healing the team. Make sure to keep them alive.`,
  brawler: `Versatile, agile and able to adapt to most enemy attacks, a brawler's body is a powerful weapons.`,
  samurai: `Samurais have a higher critical rate than average and will cause massing damage if their critical hit goes through.`,
};

export const Class = ({ hero }: Props) => {
  return (
    <Box>
      <H3>{hero.class}</H3>
      <p>{classDescriptions[hero.class]}</p>
    </Box>
  );
};
