import React from 'react';
import { SpecialAbilities } from '../generated/graphql';
import { Box } from './styled/Box';
import { H3 } from './styled/H3';

interface Props {
  specials: SpecialAbilities[];
}

export const SpecialAbilityBox = ({ specials }: Props) => {
  return (
    <>
      {specials.map((spec) => (
        <Box key={spec.title}>
          <H3>{spec.title}</H3>
          <p>{spec.description}</p>
        </Box>
      ))}
    </>
  );
};
