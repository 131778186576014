import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Hero } from '../../generated/graphql';
import { useHeroes } from '../../lib/heroContext';
import { Class } from '../Class';
import { Elements } from '../Elements';
import { HeroAbilities } from '../HeroAbilities';
import { HeroImage } from '../HeroImage';
import { HeroMeta } from '../HeroMeta';
import { HeroStats } from '../HeroStats';
import { Rarity } from '../Rarity';
import { SEO } from '../SEO';
import { SpecialAbilityBox } from '../SpecialAbilityBox';
import { Container } from '../styled/Container';
import { navigate } from 'gatsby';
import { isMobile } from '../../hooks/isMobile';

interface Props {
  data: {
    sanityHero: Hero;
  };
  pageContext: {
    slug: string;
    index: number;
    nextHero: string;
    prevHero: string;
  };
}

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  align-content: center;
  align-items: center;
  gap: 3rem;
  margin: 1rem 0;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto;
    justify-content: center;
    justify-items: center;
    text-align: center;
  }
`;

const StyledH1 = styled.h1`
  margin: 0;
  font-size: 5rem;
`;

const MetaWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const Gridder = styled.div`
  display: grid;
  gap: 3rem;
`;

const HeroNav = styled.nav`
  display: grid;
  gap: 1rem;
`;
const PrevNextArrow = styled.div`
  display: grid;
  grid-template-columns: 8rem 1fr 8rem;
  justify-content: space-between;
  .middle {
    justify-self: center;
  }
  .right {
    justify-self: flex-end;
  }
`;

const HeroTemplate = ({ data, pageContext }: Props) => {
  const [nextHero, setNextHero] = useState('');
  const [previousHero, setPreviousHero] = useState('');
  const [index, setIndex] = useState(1);
  const [totalHeroes, setTotalHeroes] = useState(1);
  const heroContext = useHeroes();
  const hero = data.sanityHero;

  const keyupListener = (key: KeyboardEvent) => {
    const blacklist = ['INPUT', 'TEXTAREA'];
    const keyTarget = key.target as HTMLElement;
    if (blacklist.includes(keyTarget.tagName)) return;
    if (key.key.toLowerCase() === 'd' && nextHero) {
      navigate(nextHero);
    } else if (key.key.toLowerCase() === 'a' && previousHero) {
      navigate(previousHero);
    } else if (key.key.toLowerCase() == 'w') {
      navigate('/heroes');
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', keyupListener);
    return () => window.removeEventListener('keyup', keyupListener);
  }, [nextHero, previousHero]);

  const getNextHero = async () => {
    if (heroContext.filteredHeroes.heroes.length) {
      const index = heroContext.filteredHeroes.heroes.findIndex(
        (tHero) => tHero.node.name === hero.name
      );
      if (index > -1 && index < heroContext.filteredHeroes.heroes.length - 1) {
        const newSlug = heroContext.filteredHeroes.heroes[index + 1].node.slug;
        setNextHero(() => `/heroes/${newSlug.current}`);
      }
    } else if (pageContext.nextHero) {
      setNextHero(() => pageContext.nextHero);
    }
  };

  const getPreviousHero = async () => {
    if (heroContext.filteredHeroes.heroes.length) {
      const index = heroContext.filteredHeroes.heroes.findIndex(
        (tHero) => tHero.node.name === hero.name
      );
      if (index > 0 && index !== -1) {
        const newSlug = heroContext.filteredHeroes.heroes[index - 1].node.slug;
        setPreviousHero(() => `/heroes/${newSlug.current}`);
      }
    } else if (pageContext.nextHero) {
      setPreviousHero(() => pageContext.nextHero);
    }
  };

  const setCounts = () => {
    if (heroContext.filteredHeroes.heroes.length) {
      const index = heroContext.filteredHeroes.heroes.findIndex(
        (tHero) => tHero.node.name === hero.name
      );
      setIndex(() => index + 1);
      setTotalHeroes(() => heroContext.filteredHeroes.heroes.length);
    }
  };

  useEffect(() => {
    getNextHero();
    getPreviousHero();
    setCounts();
  }, []);

  useEffect(() => {
    heroContext.currentHero.setHero(() => hero);
  }, [hero]);

  return (
    <Container width='wide' pad>
      <SEO title={hero.name} />
      <Gridder>
        <HeroNav>
          <div>
            <Link to='/heroes'>
              <FontAwesomeIcon icon={faArrowUp} /> All Heroes{' '}
              {!isMobile() && <code>w</code>}
            </Link>
          </div>
          <PrevNextArrow>
            <div>
              {previousHero.length > 1 && (
                <div>
                  <Link to={previousHero}>
                    &larr; Prev Hero {!isMobile() && <code>a</code>}
                  </Link>
                </div>
              )}
            </div>
            <div className='middle'>
              {heroContext.filteredHeroes.heroes.length > 0 && (
                <span>
                  {index} of {totalHeroes}
                </span>
              )}
            </div>
            {nextHero.length > 1 && (
              <div className={'right'}>
                <Link className={'right'} to={nextHero}>
                  {!isMobile() && <code>d</code>} Next Hero &rarr;
                </Link>
              </div>
            )}
          </PrevNextArrow>
        </HeroNav>

        <HeaderWrapper>
          <HeroImage heroImageId={hero.image.asset.id} alt={hero.name} />
          <StyledH1>{hero.name}</StyledH1>
        </HeaderWrapper>

        <div>
          <h2>Basic Information</h2>
          <MetaWrapper>
            <Class hero={hero} />
            <Elements hero={hero} />
            <Rarity hero={hero} />
            <HeroMeta hero={hero} />
          </MetaWrapper>
        </div>

        {hero.abilities.specials.length > 0 && (
          <div>
            <h2>Special Skills</h2>
            <MetaWrapper>
              <SpecialAbilityBox specials={hero.abilities.specials} />
            </MetaWrapper>
          </div>
        )}

        <div>
          <h2>Abilities</h2>
          <MetaWrapper>
            <HeroAbilities hero={hero} />
          </MetaWrapper>
        </div>

        <div>
          <h2>Stats</h2>
          <HeroStats hero={hero} />
        </div>
      </Gridder>
    </Container>
  );
};

export default HeroTemplate;

export const query = graphql`
  query($slug: String!) {
    sanityHero(slug: { current: { eq: $slug } }) {
      name
      medal_sources
      ...HeroMeta
      ...HeroStats
      ...HeroAbilities
      image {
        asset {
          id
          gatsbyImageData(
            width: 100
            height: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;
